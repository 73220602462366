import React, { useState } from "react"
import styled from "styled-components"
// import { Link } from "gatsby"

import { StyledFrame } from "../../styles/elements.js"
// import Button from "../Button"

const Modal100 = styled.div`
  display: flex;
  justify-content: center;
`

const ModalComp = styled.div`
  width: 100%;
  max-width: 1200px;
  display: flex;
  top: 0;
  left: 0;
  justify-content: center;
  align-items: center;
`

const ModalCard = styled.div`
  display: flex;
  justify-content: center;
  padding: 10px;
  //background: white;
  //border-radius: 15px;
  //box-shadow: 0 5px 15px rgba(0, 0, 0, 0.5);
  width: 100%;
  h1 {
    color: black;
  }
`

// to add: onClick outside modal
// to add: pass in children to make reusable. Why doesn't that work. is it iFrame?
// add X to go back to non-modal screen. BUT HOW? need just hook.

const ModalWrapper = () => {
  const [toggle, setToggle] = useState(false)

  return (
    <>
      {toggle && <Modal />}
      <ModalWrap>
        <button onClick={() => setToggle(!toggle)}>Open video</button>
      </ModalWrap>
    </>
  )
}

const Modal = ({ children }) => {
  return (
    <Modal100>
      <ModalComp>
        <ModalCard>
          <div>{children}</div>
          <StyledFrame
            title="YouTube"
            width="1120"
            height="630"
            src="https://www.youtube.com/embed/yNnAbtIyfd8?autoplay=1"
            //  src="https://www.youtube.com/embed/yNnAbtIyfd8?&autoplay=1"
            frameborder="0"
            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen
          />
          {/*           <Link to="/video"> X</Link>
           */}
        </ModalCard>
      </ModalComp>
    </Modal100>
  )
}

const ModalWrap = styled.div`
  display: flex;
  justify-content: space-around;
  margin: 0 auto;
  margin-top: 10px;
  padding: 20px;
  border-radius: 15px;
  width: auto;
  max-width: 800px;
  button {
    display: flex;
    padding: 20px;
    outline: none;
    border: none;
    background: none;
    border-radius: 10px;
    background-color: #353535;
    text-transform: uppercase;
    height: 30px;
    color: darkorange;
    font-size: 0.8em;
    align-items: center;
    cursor: pointer;
    transition: all 0.25s cubic-bezier(0.02, 0.01, 0.47, 1);
    font-weight: bold;

    &:hover {
      box-shadow: 0 15px 15px rgba(0, 0, 0, 0.16);
      transform: translate(0, -3px);
      background-color: #353535;
    }
  }
`

export default ModalWrapper
