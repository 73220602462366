import React from "react"
import styled from "styled-components"

import { LinearTitle, FlexCenter } from "./../styles/elements.js"

import Seo from "../components/Seo"
import Layout from "../components/layout.js"
import Featured from "../components/Featured"
import Button from "../components/Button"

import Modal from "../components/Modal/Modal"

const ModalBox = styled.div`
  width: 100%;
  margin-top: 40px;
  margin-bottom: 40px;
`

const Spacer = styled.div`
  height: 50px;
`

const Video = props => {
  return (
    <>
      <Layout>
        <Seo title="About CompoundYourself" />

        <Featured height="75vh">
          <FlexCenter>
            <LinearTitle>Watch the MasterClass.com trailer video</LinearTitle>
          </FlexCenter>

          <ModalBox>
            <Modal />
          </ModalBox>

          <Spacer></Spacer>

          <LinearTitle>Want to become a member of MasterClass? </LinearTitle>

          <Button>
            <a
              href="https://www.masterclass.com"
              target="_blank"
              rel="noreferrer"
            >
              Go to their platform
            </a>
          </Button>
        </Featured>
      </Layout>
    </>
  )
}

export default Video
